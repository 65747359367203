import React from "react";
import { BiRightArrow } from "react-icons/bi";

const Vision = () => {
  const coreValues = [
    "Justice",
    "Loyal",
    "Transparent",
    "Teamwork",
    "Responsible",
    "Quality and Efficient Service",
    "Excellence",
    "Change Driven",
  ];

  return (
    <div className="pt-16 pb-20 bg-yellow-100">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-12 justify-center">
          <div className="flex-1 max-w-3xl p-6 bg-white rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
            <h1 className="text-2xl md:text-4xl font-bold text-blue-700">
              Our Vision
            </h1>
            <p className="mt-4 text-base md:text-lg text-gray-700">
              To be the preferred choice in the service we provide.
            </p>

            <h1 className="text-2xl md:text-3xl font-semibold text-green-600 mt-6">
              Our Mission
            </h1>
            <p className="mt-4 text-base md:text-lg text-gray-700">
              To be a preferred and profitable share company by satisfying the
              needs of our customers by providing quality, convenient, and
              efficient services.
            </p>

            <div className="mt-6">
              <h1 className="text-2xl md:text-3xl font-semibold text-purple-600">
                Core Values
              </h1>
              <ul className="mt-4 flex flex-col gap-4">
                {coreValues.map((value, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-3 md:gap-4 animate-fadeIn"
                  >
                    <div className="text-orange-500 text-xl md:text-2xl">
                      <BiRightArrow />
                    </div>
                    <p className="text-base md:text-lg text-gray-600">
                      {value}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex-1 max-w-md">
            <img
              src="/images/kid.jpeg"
              alt="Classroom"
              className="object-cover w-full h-auto rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
