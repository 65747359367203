import { useDispatch, useSelector } from "react-redux";
import { serviceList } from "../action/serviceAction";
import { useEffect, useState } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const ServicesHome = () => {
  const dispatch = useDispatch();
  const { loading, error, service } = useSelector((state) => state.ServiceList);
  const [expandedServiceId, setExpandedServiceId] = useState(null);

  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch]);

  const handleReadMoreClick = (id) => {
    setExpandedServiceId(expandedServiceId === id ? null : id);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-16 text-red-600">
        <p className="text-xl font-semibold">Error fetching services.</p>
      </div>
    );
  }

  if (service.length === 0) {
    return (
      <div className="text-center py-16 text-gray-600">
        <p className="text-xl font-semibold">
          No services available at the moment.
        </p>
      </div>
    );
  }

  return (
    <div className="pt-16 pb-20 px-4 bg-blue-50">
      <div className="text-center mb-10">
        <h2 className="text-4xl font-extrabold text-teal-600  mb-4">
          Choose a Class for Your Child
        </h2>
        <p className="text-lg text-gray-600">
          Fun and engaging classes for every interest!
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {service?.map(({ id, name, image, description }) => (
          <div
            key={id}
            className="relative flex flex-col overflow-hidden rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl bg-white"
          >
            <img
              src={`${image}`}
              alt={name}
              className="w-full h-64 object-cover transform transition-transform duration-500 hover:scale-110"
            />
            <div className="flex flex-col flex-grow p-4">
              <h3 className="text-xl font-semibold text-center mb-2 text-teal-600 ">
                {name}
              </h3>
              <p
                className={`text-base text-center text-gray-700 leading-relaxed mb-4 transition-all duration-300 ${
                  expandedServiceId === id
                    ? "max-h-screen"
                    : "max-h-24 overflow-hidden"
                }`}
              >
                {description}
              </p>
              <button
                onClick={() => handleReadMoreClick(id)}
                className="bg-yellow-400 text-blue-800 py-2 px-4 mt-auto rounded-full flex items-center justify-center transition-transform duration-300 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              >
                {expandedServiceId === id ? (
                  <>
                    <FaArrowUp className="mr-2" /> Read Less
                  </>
                ) : (
                  <>
                    <FaArrowDown className="mr-2" /> Read More
                  </>
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesHome;
