export const navLinks = [
  {
    id: 1,
    linkText: "HOME",
    url: "/",

  },
  {
    id: 2,
    linkText: "ABOUT US",
    url: "/about-us",
 
  },
  {
    id: 3,
    linkText: "CLASS",
    url: "/class",
  
  },
  // {
  //   id: 4,
  //   linkText: "TEACHER ",
  //   url: "/staff",

  // },
  {
    id: 5,
    linkText: "BLOG",
    url: "/blog",

  },
  {
    id: 6,
    linkText: "CONTACT",
    url: "/contact",
  },
  {
    id: 7,
    linkText: "FAQs",
    url: "/faqs",
 
  },
    {
    id: 8,
    linkText: "GALLERY",
    url: "/gallery",
 
  },
        {
    id: 8,
    linkText: "PORTFOLIO",
    url: "/project",
 
  },
    
];
