import {
  SERVICE_LIST_FAIL,
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  STAFFS_LIST_FAIL,
  STAFFS_LIST_REQUEST,
  STAFFS_LIST_SUCCESS,
  TEACHERS_LIST_FAIL,
  TEACHERS_LIST_REQUEST,
  TEACHERS_LIST_SUCCESS,
} from "../constants/ServiceConstants";

export const serviceListReducer = (
  state = { loading: true, service: [] },
  action
) => {
  switch (action.type) {
    case SERVICE_LIST_REQUEST:
      return {
        loading: true,
      };
    case SERVICE_LIST_SUCCESS:
      return {
        loading: false,
        service: action.payload,
      };
    case SERVICE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// TEACHERS_LIST_REQUEST

export const teachersListReducer = (
  state = { loading: true, teacher: [] },
  action
) => {
  switch (action.type) {
    case TEACHERS_LIST_REQUEST:
      return {
        loading: true,
      };
    case TEACHERS_LIST_SUCCESS:
      return {
        loading: false,
        teacher: action.payload,
      };
    case TEACHERS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const staffListReducer = (
  state = { loading: true, staff: [] },
  action
) => {
  switch (action.type) {
    case STAFFS_LIST_REQUEST:
      return {
        loading: true,
      };
    case STAFFS_LIST_SUCCESS:
      return {
        loading: false,
        staff: action.payload,
      };
    case STAFFS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
