import AllNavbar from "../components/common/AllNavBar";
import FaqsList from "../components/faqs/FaqsList";
import { NewsLetter } from "../components/services";

const Faqs = () => {

  
  return (
    <>
      {" "}
      <AllNavbar />
      {/* <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <h1 className="text-4xl font-bold text-center text-gray-800">FAQs</h1>
      </div> */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <div className="text-center mb-10">
          <h2 className="text-4xl font-extrabold text-teal-600 mb-4">FAQs </h2>
          {/* <p className="text-lg text-gray-600">
            Fun and engaging services for everyone!
          </p> */}
        </div>
      </div>
      <div className="pt-2 px-[3%] md:px-[6%]">
        <div className=" grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3">
          <div className="md:col-span-2">
            <FaqsList />
          </div>
          <div className="md:col-span-1">
            <NewsLetter />
            <div className="mt-5">
              <img
                src="../../images/bori/6010580090079002892.jpg"
                alt=""
                className="sm:h-[400px] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
