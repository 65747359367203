import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { newsList } from "../../../action/newsAction";
import { BASE_IP } from "../../../env";
import { FiArrowRight } from "react-icons/fi";

const BlogPostsList = () => {
  const dispatch = useDispatch();
  const { loading, error, news } = useSelector((state) => state.NewsList);

  useEffect(() => {
    dispatch(newsList());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-16">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-16">
        <p className="text-red-600 text-lg font-semibold">
          Error fetching news.
        </p>
      </div>
    );
  }

  if (!news || news.length === 0) {
    return (
      <div className="text-center py-16">
        <p className="text-gray-600 text-lg font-semibold">
          No blog posts available.
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 ">
      {/* <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
        Blog Posts
      </h2> */}
      <div className="overflow-x-auto">
        <div className="pt-10 pb-16 bg-gradient-to-r from-yellow-100 via-orange-100 to-pink-100">
          {/* Section Header */}

          {/* News Grid */}
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {news.map((item) => (
              <div
                key={item.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden group transition-transform transform hover:scale-105 hover:shadow-2xl"
              >
                <Link to={`/blog/${item.id}`} className="relative block">
                  <img
                    src={`${item.image}`}
                    alt={item.title}
                    className="object-cover w-full h-64 rounded-t-lg"
                  />
                  <div className="absolute top-4 right-4 bg-yellow-500 text-white px-3 py-1 text-xs font-semibold rounded-full shadow-lg">
                    {/* Category Placeholder */}
                    {/* {item.category} */}
                  </div>
                </Link>
                <div className="p-6 flex flex-col">
                  <Link
                    to={`/blog/${item.id}`}
                    className="text-lg font-bold text-blue-600 hover:text-blue-400 transition-colors"
                  >
                    {item.title}
                  </Link>
                  <p className="mt-3 text-gray-700 text-sm">
                    {item.content.length > 150
                      ? `${item.content.slice(0, 150)}...`
                      : item.content}
                  </p>
                  <Link
                    to={`/blog/${item.id}`}
                    className="mt-4 flex items-center text-yellow-500 hover:underline"
                  >
                    <span className="uppercase font-semibold">Read more</span>
                    <FiArrowRight className="ml-2 text-lg" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostsList;
