// Modal.js
import React from "react";
import { BASE_IP } from "../../env";

const Modal = ({ member, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4">{`${member.first_name} ${member.last_name}`}</h2>
        <img
          className="w-28 h-28 rounded-full mx-auto mb-4 border-4 border-teal-400"
          src={`${BASE_IP}${member.profile_picture}`}
          alt={`${member.first_name} ${member.last_name}`}
        />
        <p className="text-gray-700 mb-4">{member.phone_number}</p>
        <p className="text-gray-700 mb-4">{member.email}</p>

        <p className="text-gray-700 mb-4">{member.qualifications}</p>

        <p className="text-gray-700 mb-4">{member.bio}</p>
        <button
          className="mt-4 px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
