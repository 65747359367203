import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { newsList } from "../action/newsAction";
import { BASE_IP } from "../env";
import AllNavbar from "../components/common/AllNavBar";

const BlogDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { news } = useSelector((state) => state.NewsList);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    dispatch(newsList());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 5); // Cycle through images 0-4
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const selectedNews = news?.find((item) => item.id === id);
  const relatedNews = news?.filter((item) => item.id !== id);

  if (!selectedNews) {
    return <div className="text-center text-gray-500">Loading...</div>;
  }

  const images = [
    selectedNews.image,
    selectedNews.image2,
    selectedNews.image3,
    selectedNews.image4,
    selectedNews.image5,
  ];

  return (
    <>
      <AllNavbar />

      <div className="container mx-auto px-4 py-10 mt-28">
        <h1 className="text-4xl font-bold mb-4 text-center text-gray-800">
          {selectedNews.title}
        </h1>
        <div className="relative mb-6">
          <img
            src={`${images[currentImageIndex]}`}
            alt={`Slide ${currentImageIndex + 1}`}
            className="w-full h-96 object-cover rounded-lg" // Increased height to 96
          />
        </div>
        <p className="text-lg text-gray-700 mb-4">{selectedNews.content}</p>
        <p className="text-sm text-gray-500 text-right">
          Published on:{" "}
          {new Date(selectedNews.publication_date).toLocaleDateString()} by{" "}
          {selectedNews.author}
        </p>

        <h2 className="text-3xl font-semibold mt-10 mb-4 text-gray-800 text-center">
          Related Posts
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {relatedNews.map((item) => (
            <div
              key={item.id}
              className="bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 overflow-hidden"
            >
              <Link to={`/blog/${item.id}`}>
                <img
                  src={`${item.image}`}
                  alt={item.title}
                  className="object-cover w-full h-48 rounded-t-lg"
                />
              </Link>
              <div className="p-4">
                <h3 className="text-lg font-bold text-gray-800">
                  {item.title}
                </h3>
                <p className="text-gray-600">{item.summary}</p>
                <Link
                  to={`/blog/${item.id}`}
                  className="text-blue-600 hover:underline mt-2 block font-semibold"
                >
                  Read more
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogDetailPage;
