import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { staffsList, teachersList } from "../action/serviceAction";
import { BASE_IP } from "../env";

const InstructorsList = () => {
  const dispatch = useDispatch();

  const { teacher } = useSelector((state) => state.TeacherList);
  const { staff } = useSelector((state) => state.StaffList);

  console.log("teacher", teacher);
  console.log("staff", staff);

  useEffect(() => {
    dispatch(teachersList());
    dispatch(staffsList());
  }, [dispatch]);

  return (
    <div className="container mx-auto px-4 py-8 bg-yellow-50">
      <h1 className="text-3xl font-bold text-center mb-8 text-teal-600">
        Meet Our Amazing Instructors
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {teacher?.map((instructor) => (
          <div
            key={instructor.id}
            className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 hover:shadow-2xl flex flex-col items-center text-center"
          >
            <div className="relative w-32 h-32 mb-4">
              <img
                src={`${instructor.profile_picture}`}
                alt={`${instructor.first_name} ${instructor.last_name}`}
                className="w-full h-full object-cover rounded-full transition-transform duration-300 hover:rotate-12"
              />
              <div className="absolute inset-0 rounded-full bg-blue-200 opacity-50 transform scale-110 animate-pulse"></div>
            </div>
            <h2 className="text-xl font-semibold text-blue-800 mb-2 transition-colors duration-300 hover:text-blue-500">
              {`${instructor.first_name} ${instructor.last_name}`}
            </h2>
            {/* <p className="text-gray-600 mb-2">
              {instructor.qualifications || "N/A"}
            </p>
            <p className="text-gray-600 mb-2">{instructor.department || " "}</p>
            <p className="text-gray-600">{instructor.bio || " "}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstructorsList;
