import { useDispatch, useSelector } from "react-redux";
import { serviceList } from "../action/serviceAction";
import AllNavbar from "../components/common/AllNavBar";
import {
  Categories,
  Counter,
  Feeds,
  Services as ServicesList,
  Team,
} from "../components/common/page-componets";
import { services } from "../data/dummyData";
import ServicesForServices from "./ServicesForSerivces";
import ServicesHome from "./ServicesHome";
import ServicesTwo from "./ServicesHome";

const Services = () => {

  return (
    <>
      <AllNavbar />

      {/* New Section with Gradient Background */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <div className="text-center mb-10">
          <h2 className="text-4xl font-extrabold text-teal-600 mb-4">
            Our Classes
          </h2>
          <p className="text-lg text-gray-600">
            Fun and engaging Classes for everyone!
          </p>
        </div>
      </div>

      <div className=" px-[3%] md:px-[6%] mt-8 lg:mt-2">
        <ServicesForServices />
        {/* <Vision /> */}
      </div>
    </>
  );
};

export default Services;
