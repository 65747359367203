import { BiRightArrow } from "react-icons/bi";

const AboutUs = () => {
  return (
    <div className="pt-16 pb-20 bg-yellow-50">
      <div className="container mx-auto flex flex-col md:flex-row gap-12 justify-center items-center">
        <div className="relative flex-1 max-w-lg md:max-w-3xl border rounded-lg overflow-hidden shadow-lg bg-white transform transition-transform duration-500 animate-fadeIn">
          <img
            src="../../../../images/bori/gibi.jpg"
            alt="boricof"
            className="object-cover w-full h-80 md:h-96 rounded-lg transition-transform duration-500 ease-in-out transform hover:rotate-6 animate-zoomIn"
          />
        </div>
        <div className="relative flex-1 max-w-lg p-6 bg-white rounded-lg shadow-lg animate-fadeIn">
          <h1 className="text-2xl md:text-4xl font-extrabold text-teal-600 mb-6 text-center animate-fadeInUp">
            About Borikof Trading Share Company
          </h1>
          <p className="text-base md:text-lg text-gray-700 mb-6 text-center animate-fadeInUp">
            Borikof Trading Share Company is a trusted provider of educational
            services. Our mission is to ensure that every student receives
            comprehensive, timely, and supportive learning experiences,
            fostering academic achievement and personal development.
          </p>
          <div className="space-y-4 md:space-y-6">
            <div className="flex items-start gap-x-3 md:gap-x-4 animate-fadeInUp">
              <div className="icon-box text-teal-500 bg-teal-100 p-3 md:p-4 rounded-full shadow-lg transition-transform duration-300 hover:scale-110 animate-pulse">
                <BiRightArrow size={24} className="md:size-30" />
              </div>
              <div>
                <h2 className="font-semibold text-base md:text-lg text-gray-800">
                  Your learning and well-being are our top priorities.
                </h2>
              </div>
            </div>

            <div className="flex items-start gap-x-3 md:gap-x-4 animate-fadeInUp">
              <div className="icon-box text-teal-500 bg-teal-100 p-3 md:p-4 rounded-full shadow-lg transition-transform duration-300 hover:scale-110 animate-pulse">
                <BiRightArrow size={24} className="md:size-30" />
              </div>
              <div>
                <h2 className="font-semibold text-base md:text-lg text-gray-800">
                  We ensure timely and reliable educational services.{" "}
                </h2>
              </div>
            </div>

            <div className="flex items-start gap-x-3 md:gap-x-4 animate-fadeInUp">
              <div className="icon-box text-teal-500 bg-teal-100 p-3 md:p-4 rounded-full shadow-lg transition-transform duration-300 hover:scale-110 animate-pulse">
                <BiRightArrow size={24} className="md:size-30" />
              </div>
              <div>
                <h2 className="font-semibold text-base md:text-lg text-gray-800">
                  Our team is dedicated to providing supportive and respectful
                  educational services.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
