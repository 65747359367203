import React, { useEffect, useState } from "react";
import { FaQuoteRight } from "react-icons/fa";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { testimonialList } from "../../../action/testimonialAction";

const Testimonial = () => {
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const { loading, error, testimonial } = useSelector(
    (state) => state.TestimonialList
  );

  useEffect(() => {
    dispatch(testimonialList());
  }, [dispatch]);

  useEffect(() => {
    if (testimonial?.length > 0) {
      const slider = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % testimonial?.length);
      }, 3000);
      return () => clearInterval(slider);
    }
  }, [testimonial]);

  const handlePrevClick = () => {
    setIndex(
      (prevIndex) => (prevIndex - 1 + testimonial?.length) % testimonial?.length
    );
  };

  const handleNextClick = () => {
    setIndex((prevIndex) => (prevIndex + 1) % testimonial?.length);
  };

  const URL_IO = "http://127.0.0.1:8000"; // Base URL for your backend

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-yellow-500 border-solid"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-16 text-red-600">
        <p className="text-xl font-semibold">Error fetching testimonials.</p>
      </div>
    );
  }

  if (!testimonial || testimonial.length === 0) {
    return (
      <div className="text-center py-16 text-gray-600">
        <p className="text-xl font-semibold">No testimonials available.</p>
      </div>
    );
  }

  return (
    <div className="pt-20 pb-16 bg-blue-50">
      <div className="text-center">
        <h2 className="text-4xl font-extrabold text-green-600 mb-4">
          What Our Patreners Say!
        </h2>
        <h1 className="text-2xl font-semibold text-gray-800 mb-8">
          Their Favorite School Moments
        </h1>
      </div>
      <div className="relative max-w-4xl w-full mx-auto h-[400px] md:h-[300px] overflow-hidden bg-white rounded-lg shadow-lg border border-yellow-200">
        {testimonial?.map((item, currentIndex) => {
          const { id, photo, name, title, description } = item;

          let position = "nextSlide";
          if (currentIndex === index) {
            position = "activeSlide";
          } else if (
            currentIndex === index - 1 ||
            (index === 0 && currentIndex === testimonial.length - 1)
          ) {
            position = "lastSlide";
          }

          return (
            <div
              className={`absolute inset-0 flex flex-col items-center justify-center p-6 transition-transform duration-500 ease-in-out ${position}`}
              key={id}
            >
              <img
                src={photo}
                alt={name}
                className="w-28 h-28 rounded-full border-4 border-pink-300 shadow-xl mb-4"
              />
              <div className="text-center p-6 bg-yellow-100 rounded-lg shadow-md">
                <h3 className="text-xl font-bold text-blue-600 mb-2">{name}</h3>
                <p className="text-lg text-gray-700 mb-4">{title}</p>
                <p className="italic text-gray-800">"{description}"</p>
                {/* <FaQuoteRight className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-orange-400 text-4xl" /> */}
              </div>
            </div>
          );
        })}
        <button
          className="w-12 h-12 absolute top-1/2 left-4 transform -translate-y-1/2 bg-orange-300 text-blue-600 border border-blue-600 rounded-full shadow-lg flex items-center justify-center transition-transform duration-300 hover:bg-orange-400"
          onClick={handlePrevClick}
        >
          <BsChevronLeft size={24} />
        </button>
        <button
          className="w-12 h-12 absolute top-1/2 right-4 transform -translate-y-1/2 bg-orange-300 text-blue-600 border border-blue-600 rounded-full shadow-lg flex items-center justify-center transition-transform duration-300 hover:bg-orange-400"
          onClick={handleNextClick}
        >
          <BsChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

export default Testimonial;
