import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FiCheck, FiLayers, FiUsers } from "react-icons/fi";

const Speciality = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-fadeInUp");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <div className="py-16 bg-gradient-to-r from-pink-300 via-purple-400 to-indigo-400">
      <div className="container mx-auto flex flex-wrap gap-10">
        <div
          className="flex-1 basis-[20rem] p-8 bg-white rounded-xl shadow-lg transform transition-transform duration-500 hover:scale-105 hover:shadow-2xl hover:bg-gray-100 relative"
          style={{
            backgroundImage: "url('../../../../images/bori/gibi1.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="text-4xl font-extrabold text-center text-green-500 mb-6">
            Join Our School!
          </h1>
          <p className="text-green-50 text-center text-lg mb-6">
            We are dedicated to finding passionate individuals who are committed
            to compassionate, safe, and courteous service. If you share our
            dedication, we want you on our team!
          </p>
          <div className="mt-6 flex justify-center">
            <Link
              to="/about-us"
              className="btn btn-primary text-white bg-blue-600 hover:bg-blue-700 rounded-full py-2 px-6 shadow-md transition-transform duration-300 transform hover:scale-105"
            >
              Read More
            </Link>
          </div>
        </div>
        <div className="flex-1 basis-[20rem] relative overflow-hidden rounded-xl shadow-lg hover:scale-105 transition-transform duration-500">
          <img
            ref={imageRef}
            src="../../../../images/bori/big prize.jpg"
            alt="CareNetwork Vehicle"
            className="object-cover w-full h-96 transition-transform duration-500 hover:scale-125" // Increased height here
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-40"></div>
          <div className="absolute bottom-4 left-4 text-white text-lg font-semibold bg-gray-800 bg-opacity-60 px-4 py-2 rounded-md">
            Join Us
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speciality;
