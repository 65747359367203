import AllNavbar from "../components/common/AllNavBar";
import { BlogPostsList, Pagination } from "../components/common/page-componets";
import { feeds } from "../data/dummyData";

const Blog = () => {
  return (
    <>
      {" "}
      <AllNavbar />
      {/* New Section with Gradient Background */}
      {/* <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <h1 className="text-4xl font-bold text-center text-gray-800">
          Blog Posts
        </h1>
      </div> */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <div className="text-center mb-10">
          <h2 className="text-4xl font-extrabold text-teal-600 mb-4">
            Blog Posts
          </h2>
          {/* <p className="text-lg text-gray-600">
            Fun and engaging services for everyone!
          </p> */}
        </div>
      </div>
      <div className="pt-2 px-[3%] md:px-[6%]">
        <BlogPostsList />
        <Pagination itemsPerPage={6} pageData={feeds} />
      </div>
    </>
  );
};

export default Blog;
