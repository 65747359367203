import axios from "axios";
import {
  CLIENT_LIST_FAIL,
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
} from "../constants/ClientConstants";
import {
  PROJECT_DETAILS_FAIL,
  PROJECT_DETAILS_REQUEST,
  PROJECT_DETAILS_SUCCESS,
} from "../constants/projectConstants";
import { BASE_IP } from "../env";

export const clientList = () => async (dispatch) => {
  dispatch({
    type: CLIENT_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(`${BASE_IP}/clients/`);

    dispatch({
      type: CLIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_LIST_FAIL,
      payload: error,
    });
  }
};

export const testimonialDetailById = (id) => async (dispatch) => {
  dispatch({ type: PROJECT_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(
      `https://api.mechotrealestate.com/project/${id}`
    );
    dispatch({
      type: PROJECT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_DETAILS_FAIL,
      payload: error,
    });
  }
};
