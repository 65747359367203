import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaArrowDown,
  FaArrowUp,
  FaCar,
  FaHospital,
  FaShoppingCart,
  FaPlane,
  FaFile,
  FaTree,
  FaUser,
} from "react-icons/fa";
import { serviceList } from "../action/serviceAction";
import { BASE_IP } from "../env";

const iconList = [FaCar, FaHospital, FaShoppingCart, FaPlane];

const ServicesForServices = () => {
  const dispatch = useDispatch();
  const { loading, error, service } = useSelector((state) => state.ServiceList);
  const [expandedServiceId, setExpandedServiceId] = useState(null);

  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch]);

  const handleReadMoreClick = (id) => {
    setExpandedServiceId(expandedServiceId === id ? null : id);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-16 text-red-600">
        <p className="text-xl font-semibold">Error fetching services.</p>
      </div>
    );
  }

  if (!service || service.length === 0) {
    return (
      <div className="text-center py-16 text-gray-600">
        <p className="text-xl font-semibold">No services available.</p>
      </div>
    );
  }

  return (
    <div className="pt-16 pb-20 px-4 bg-blue-50">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-extrabold text-blue-600 mb-6">
          Choose a Class for Your Child
        </h2>
        <p className="text-lg text-blue-700 max-w-3xl mx-auto leading-relaxed">
          Explore our diverse range of classes designed to provide your child
          with enriching experiences and opportunities for growth. Whether it's
          a creative workshop or a fun activity, we have something to spark
          every child's interest.
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {service.map(({ id, name, image, description }) => (
            <div
              key={id}
              className="relative flex flex-col overflow-hidden rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl bg-white"
            >
              <img
                src={`${image}`}
                alt={name}
                className="w-full h-64 object-cover transform transition-transform duration-500 hover:scale-110"
              />
              <div className="flex flex-col flex-grow p-4">
                <h3 className="text-xl font-semibold text-center mb-2 text-teal-600 ">
                  {name}
                </h3>
                <p
                  className={`text-base text-center text-gray-700 leading-relaxed mb-4 transition-all duration-300 ${
                    expandedServiceId === id
                      ? "max-h-screen"
                      : "max-h-24 overflow-hidden"
                  }`}
                >
                  {description}
                </p>
                <button
                  onClick={() => handleReadMoreClick(id)}
                  className="bg-yellow-400 text-blue-800 py-2 px-4 mt-auto rounded-full flex items-center justify-center transition-transform duration-300 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-300"
                >
                  {expandedServiceId === id ? (
                    <>
                      <FaArrowUp className="mr-2" /> Read Less
                    </>
                  ) : (
                    <>
                      <FaArrowDown className="mr-2" /> Read More
                    </>
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="mt-16 px-8 py-12 bg-pink-50 rounded-lg shadow-lg">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-pink-600 mb-6">
            Additional Activities
          </h2>
          <p className="text-lg text-pink-700 max-w-3xl mx-auto leading-relaxed">
            Discover our range of engaging programs designed to enrich our
            community. From educational enrichment to fun-filled summer camps,
            our activities are crafted to foster growth and joy. Join us in our
            mission to provide meaningful and enjoyable experiences for
            everyone.
          </p>
        </div>

        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="flex items-start space-x-6 bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <FaUser className="text-white bg-pink-500 p-4 rounded-full text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold text-pink-600 mb-2">
                Enrichment Programs
              </h3>
              <p className="text-pink-700">
                Engage in programs that spark curiosity and growth. Our
                enrichment programs are designed to challenge and inspire,
                offering a range of activities to stimulate learning and
                creativity.
              </p>
            </div>
          </div>

          <div className="flex items-start space-x-6 bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <FaTree className="text-white bg-pink-500 p-4 rounded-full text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold text-pink-600 mb-2">
                Summer Camp
              </h3>
              <p className="text-pink-700">
                Experience the great outdoors with our exciting summer camps.
                Our activities are designed to promote teamwork, adventure, and
                fun, all while enjoying nature and making new friends.
              </p>
            </div>
          </div>

          <div className="flex items-start space-x-6 bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <FaFile className="text-white bg-pink-500 p-4 rounded-full text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold text-pink-600 mb-2">
                Before School
              </h3>
              <p className="text-pink-700">
                Start your day right with our before-school programs. These
                activities are designed to provide a positive and productive
                start to your day, setting the tone for success and enthusiasm.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ServicesForServices;
