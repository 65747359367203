import { BiPlay } from "react-icons/bi";
import { NavLink } from "react-router-dom";

const Hero = () => {
  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center bg-fixed bg-animate"
      style={{ backgroundImage: "url('images/banner.jpg')" }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-blue-400/40 to-blue-200/30"></div>
      <div className="relative text-center text-white px-[3%] md:px-[6%] mt-[-10%]">
        <h1 className="text-3xl md:text-5xl font-extrabold mb-6 animate-bounce ">
          Welcome to Borikof Trading Share Company
        </h1>

        <div className="mt-6 flex justify-center gap-x-3">
          <NavLink to="/contact">
            <button className="btn btn-primary">Join Borikof Academy</button>
          </NavLink>
          <button className="icon-box !opacity-100 relative before:w-full before:absolute before:h-full before:rounded-full before:bg-[#0176b4] hover:!bg-[#003b5b] before:animate-ping bg-[#035581]">
            <BiPlay />
          </button>
        </div>
        {/* <div className="mt-6 overflow-hidden rounded-lg sm:cursor-pointer flex justify-center w-fit mx-auto">
          <div className="px-3 py-1 bg-primary">
            <span className="text-white">Buy</span>
          </div>
          <div className="px-3 py-1 bg-[#0d3c55]">
            <span>Rent</span>
          </div>
          <div className="px-3 py-1 bg-[#0d3c55]">
            <span>Sell</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Hero;
